/// This section has cta green background

import React from 'react'
import SlideInTop from '../utility/slideInTopAnimation'
import ScaleUp from '../utility/scaleUpAnimation'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Card from '../common/card'

const Section6 = props => {
  const [ref, inView] = useInView({ threshold: 0.5 })
  return (
    <div className="maingrad py-24">
      <div className="section">
        <div className='mb-16'>
          <SlideInTop>
          <h2 className="text-white text-3xl md:text-5xl font-semibold text-center pb-4">{props.title}</h2>
        </SlideInTop>
        </div>
        <div className='md:w-1/4 mx-auto'>
          <ScaleUp>
            <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.top}</p>
          </ScaleUp>
        </div>
          <div className='flex space-x-4 mt-8 justify-items-stretch'>
            <div className='w-1/4'></div>
            <motion.div
              className='w-1/2'
              animate={
                inView
                  ? {
                      scale: [0, 1.2, 1],
                      opacity: [0, 1],
                    }
                  : {
                      scale: 0,
                      opacity: [0, 0],
                    }
              }
              ref={ref}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.secondRow1}</p>
            </motion.div>
            <motion.div
                className='w-1/2'
                animate={
                  inView
                    ? {
                        scale: [0, 1.2, 1],
                        opacity: [0, 1],
                      }
                    : {
                        scale: 0,
                        opacity: [0, 0],
                      }
                }
                ref={ref}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.secondRow2}</p>
              </motion.div>
              <div className='w-1/4'></div>
        </div>
        <div className='flex space-x-4 mt-8 justify-items-stretch'>
            <div className='w-1/6'></div>
            <motion.div
              className='w-1/2'
              animate={
                inView
                  ? {
                      scale: [0, 1.2, 1],
                      opacity: [0, 1],
                    }
                  : {
                      scale: 0,
                      opacity: [0, 0],
                    }
              }
              ref={ref}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.thirdRow1}</p>
            </motion.div>
            <motion.div
                className='w-1/2'
                animate={
                  inView
                    ? {
                        scale: [0, 1.2, 1],
                        opacity: [0, 1],
                      }
                    : {
                        scale: 0,
                        opacity: [0, 0],
                      }
                }
                ref={ref}
                transition={{ duration: 0.6, delay: 0.8 }}
              >
                <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.thirdRow2}</p>
              </motion.div>
              <div className='w-1/6'></div>
        </div>
        <div className='flex space-x-4 mt-8 justify-items-stretch'>
            <div className='w-1/8'></div>
            <motion.div
              className='w-1/2'
              animate={
                inView
                  ? {
                      scale: [0, 1.2, 1],
                      opacity: [0, 1],
                    }
                  : {
                      scale: 0,
                      opacity: [0, 0],
                    }
              }
              ref={ref}
              transition={{ duration: 0.6, delay: 1.0 }}
            >
              <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.bottom1}</p>
            </motion.div>
            <motion.div
                className='w-1/2'
                animate={
                  inView
                    ? {
                        scale: [0, 1.2, 1],
                        opacity: [0, 1],
                      }
                    : {
                        scale: 0,
                        opacity: [0, 0],
                      }
                }
                ref={ref}
                transition={{ duration: 0.6, delay: 1.2 }}
              >
                <p className="text-xl font-semibold p-4 rounded text-center bg-white drop-shadow-lg">{props.bottom2}</p>
              </motion.div>
              <div className='w-1/8'></div>
        </div>
      </div>
    </div>
            
      
    
  )
}

export default Section6
